import React from 'react';
import { Layout, Menu, Spin, notification } from 'antd';
import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth';
import { useAuthState } from 'react-firebase-hooks/auth';
import firebase, { FirebaseAuth } from './firebase'
import logo from './assets/logo.png';

const auth = firebase.auth()
auth.languageCode = 'pt';

const App: React.FC = () => {
  const [user, loading, error] = useAuthState(auth);

  React.useEffect(() => {
    if (!error) return;
    notification.error({
      message: 'Erro na autenticação - ' + error.code,
      description: error.message,
    });
  }, [error]);

  React.useEffect(() => {
    if (!user) return;
    firebase.functions().httpsCallable('testingAuth').call(null).then(response => console.log('Response', response))
  }, [user])

  if (loading) {
    return (
      <div className="full-loading"><Spin /></div>
    );
  }

  if (!user) {
    const uiConfig = {
      signInFlow: 'popup',
      signInOptions: [
        {
          provider: FirebaseAuth.GoogleAuthProvider.PROVIDER_ID,
          scopes: [
            'https://www.googleapis.com/auth/contacts.readonly',
            'https://www.googleapis.com/auth/spreadsheets',
            'https://www.googleapis.com/auth/drive.readonly',
          ],
          customParameters: {
            // Forces account selection even when one account
            // is available.
            prompt: 'select_account'
          }
        },
      ],
      callbacks: {
        signInSuccessWithAuthResult: () => false,
      },
    };
    return (
      <div>
        <StyledFirebaseAuth className="firebaseui" uiConfig={uiConfig} firebaseAuth={auth}/>
      </div>
    );
  }

  return (
    <Layout className="layout">
      <Layout.Header>
        <img className="header-logo" src={logo} alt="Lenarge" />
        <Menu className="header-menu" theme="light" mode="horizontal" defaultSelectedKeys={['2']} >
          <Menu.Item key="1">nav 1</Menu.Item>
          <Menu.Item key="2">nav 2</Menu.Item>
          <Menu.Item key="3">nav 3</Menu.Item>
        </Menu>
      </Layout.Header>
      <Layout.Content style={{ padding: '24px 50px 0' }}>
        <div style={{ background: '#fff', padding: 24 }}>Content</div>
      </Layout.Content>
      <Layout.Footer style={{ textAlign: 'center' }}>Lenarge ©2019 Created by FACSS</Layout.Footer>
    </Layout>
  );
}

export default App;
