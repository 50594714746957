import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/analytics';
import 'firebase/functions';

const firebaseConfig = {
  apiKey: "AIzaSyB9xCwkSIsNNhNc-bNo5TMXnuI_UsT6NvU",
  authDomain: "lenarge-bots.firebaseapp.com",
  databaseURL: "https://lenarge-bots.firebaseio.com",
  projectId: "lenarge-bots",
  storageBucket: "lenarge-bots.appspot.com",
  messagingSenderId: "993284171096",
  appId: "1:993284171096:web:070d9bf770f806d594312d",
  measurementId: "G-K8J9GB3ND9"
};

const firebaseApp = firebase.initializeApp(firebaseConfig);

export const FirebaseAuth = firebase.auth;

export default firebaseApp;
